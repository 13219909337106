@import 'bootstrap/scss/functions';
@import '~base/variables';

//z-index values
$zindex-negative: -1;
$zindex-zero: 0;
$zindex-lowest: 1;
$zindex-low: 2;
$zindex-medium: 3;
$zindex-high: 4;
$zindex-higher: 5;
$zindex-earlyaccess-badge: 15;
$zindex-highest: 999;
$zindex-max-highest: 9999;
$zindex-above-salesfloor: 100000000;

//font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 900;

// font sizes
$font-size-1: rem(4);
$font-size-2: rem(8);
$font-size-3: rem(16);
$font-size-4: rem(20);
$font-size-5: rem(24);
$font-size-6: rem(32);
$font-size-7: rem(48);
$font-size-8: rem(64);
$font-size-40: rem(40);
$font-size-42: rem(42);
$font-size-10: rem(10);
$font-size-12: rem(12);
$font-size-14: rem(14);
$font-size-18: rem(18);
$font-size-50: rem(50);
$font-size-07: rem(7);
$font-size-35: rem(35);
$font-size-15: rem(15);
$font-size-9: rem(9);

$backgroud-color-beige: #DED6CB;
// bootstrap gutter width
$grid-gutter-width: 32px;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1362px,
    jw: 1440px, // header breakpoint
    xxl: 1441px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1342px,
    xxl: 1441px
);

$header-height: rem(85);
$stripe-banner-height: rem(40);