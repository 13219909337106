@import '../global/global';

$CTAmaxWidth: rem(416);

.font-regular {
    font-family: $font-primary;
    font-weight: $font-weight-regular;
}

.font-medium {
    font-family: $font-medium;
    font-weight: $font-weight-medium;
}

.font-demi {
    font-family: $font-demi;
    font-weight: $font-weight-semi-bold;
}


.m-left {
    margin-right: auto;
}

.m-right {
    margin-left: auto;
}

.m-center {
    @extend .m-left;
    @extend .m-right;
}

.image-container {
    width: 100%;
}

.text-container-placement {
    display: flex;
    flex-direction: column;

    &.left {
        align-items: flex-start;

        .heading-text {
            text-align: left;

            .heading {
                text-align: left;
            }

            .description {
                text-align: left;
            }
        }

        .cta-text {
            justify-content: flex-start;
        }
    }

    &.center {
        align-items: center;
        justify-content: center;

        .heading-text {
            text-align: center;

            .heading {
                text-align: center;
            }

            .description {
                text-align: center;
            }
        }

        .cta-text {
            align-items: center;
        }
    }

    &.right {
        align-items: flex-end;

        .heading-text {
            text-align: right;

            .heading {
                text-align: right;
            }

            .description {
                text-align: right;
            }
        }

        .cta-text {
            justify-content: flex-end;
        }
    }

    @include media-breakpoint-up(md) {
        position: absolute;

        &.bottom-left {
            bottom: 0;
            left: 0;
        }

        &.bottom-center {
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &.bottom-right {
            bottom: 0;
            right: 0;
        }

        &.center-left {
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &.center-center {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }

        &.center-right {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &.top-left {
            top: 0;
            left: 0;
        }

        &.top-center {
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        &.top-right {
            top: 0;
            right: 0;
        }
    }
}

.flex-row-container {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }
}

.flex-item-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.experience-component {
    a {
        &:hover {
            text-decoration: none;
        }
    }

    .storepage.m-three-row & {
        max-width: rem(1440);
        margin: 0 auto;
    }
}

.video-controls {
    top: rem(20);
}

.video-control {
    position: relative;

    &.left {
        left: rem(20);
    }

    &.right {
        right: rem(20);
    }

    .control-icons {
        background-color: $primary-black;
        display: inline-block;
    }
}

.no-bleed-container {
    padding-left: rem(12);
    padding-right: rem(12);

    @include media-breakpoint-up(md) {
        padding-left: rem(32);
        padding-right: rem(32);
    }

    @include media-breakpoint-up(lg) {
        padding-left: rem(80);
        padding-right: rem(80);
    }
}

.pd-body-text,
.pd-cta-text {
    font-size: $font-size-14;
}

.pd-default-CTA-border {
    border-width: rem(1);
    border-style: solid;
}

.pd-default-CTA-border-bottom {
    border-bottom-width: rem(1);
    border-bottom-style: solid;
}

.photo-tile-image {
    width: 100%;
}

@include media-breakpoint-up(md) {
    .mw-md-50 {
        max-width: 50%;
    }

    .mw-md-20 {
        max-width: 20%;
    }
}

// CTA Buttons in PD Component
.pd-default-CTA-button {
    &:hover {
        opacity: 0.70;
    }
}

// CTA Links in PD Component
.storepage {
    a:not(.pd-default-CTA-button) {
        text-decoration: underline;
        text-underline-offset: rem(3);

        &:hover {
            text-decoration: none;
        }
    }

    .customer-service-container {
        a {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}