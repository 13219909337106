@font-face{
    font-family:"Avenir Next LT W05 Regular";
    src:url("../../fonts/6149662/146f526d-792d-4257-ba12-0fb6fbb8e31e.woff2") format("woff2"),url("../../fonts/6149662/2a1d06fe-73d7-4df1-9d59-5227c3c01d03.woff") format("woff");
}
@font-face{
    font-family:"Avenir Next LT W05 Medium";
    src:url("../../fonts/6149672/9548fa3a-05c3-47ef-a6d5-f2f6fef54f84.woff2") format("woff2"),url("../../fonts/6149672/7667f1c1-c3ca-4520-aead-66f9d93d55a0.woff") format("woff");
}
@font-face{
    font-family:"Avenir Next LT W05 Demi";
    src:url("../../fonts/6149639/11ba579d-59f5-479e-b2dd-411ca230f60c.woff2") format("woff2"),url("../../fonts/6149639/f740c173-ce1a-4e47-a4e3-fab0a45da815.woff") format("woff");
}

//
// Font Stacks
//
$font-primary: 'Avenir Next LT W05 Regular', sans-serif;
$font-medium: 'Avenir Next LT W05 Medium', sans-serif;
$font-demi: 'Avenir Next LT W05 Demi', sans-serif;

$crimson: $font-primary;
$crimson-semibold: $font-primary;
$crimson-bold: $font-primary;
$crimson-italic: $font-primary;
$crimson-italic-semibold: $font-primary;
$crimson-italic-bold: $font-primary;
$gotham-light: $font-primary;
$gotham-regular: $font-primary;
$gotham-italic: $font-primary;
$gotham-semibold: $font-primary;
$gotham-bold: $font-primary;
$text-security-disc: $font-primary;

$font-dashboard-text: $font-primary;
