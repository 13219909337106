$lilly-pink: #e00083;
$lilly-navy: #19193d;
$monza: #e00024;
$emperor: #4f4f4f;
$boulder: #767676;
$silver: #ccc;
$mercury: #e1e1e1;
$wildsand: #f6f6f6;
$mulled-wine: #474764;
$alto: #cecece;
$dusty-gray: #979797;
$navy-blue: #006;
$french-rose: #e60f45;
$anzac: #dca144;
$alabaster: #fafafa;
$hot-pink: #e6007a;
$lola: #e7dde7;
$moonraker: #e5cbf7;
$scorpion: #575757;
$lavender-blush: #ffedf0;
$selago: #faf7fe;
$light-pink: #fcf4f9;
$light-purple: #f0eeff;
$light-grayish-blue: #f4f6fc;
$gallery: #f0f0f0;
$ghostwhite: #f3f3f7;
$magenta-pink: #fcf4f9;
$rustic: #bbb1b1;
$venetian-red: #d0021b;
$whiteish-grey: #eaeaea;
$placebo-purple: #f7f1fd;
$magnolia: #fcfaff;
$pale-lavender: #e5daff;
$whisper: #e7e7e7;
$concrete: #f3f3f3;
$snow: #fff4fa;
$titan-white: #ebe9ff;
$crimson-red: #e30083;
$gainsboro: #d8d8d8;
$cadmium-red: #e00023;
$forest-green: #228b22;
$submit-form-disable-grey: #bdbbba;
$dark-blue: #19183e;
$light-red: #dccfd4;
$grey: #666666;
$charcoal: #404040;

// ISPU disabled color on PDP
$dimy-grey: #bdbbba;

// primary colors
$primary-black: #2e2e2e;
$primary-grey: #696969;

// Grayscales
$white: #ffffff;
$black: #000000;
$light-clay: #e7e6e6;
$lighter-clay: #f4f4f4;
$clay: #787474;
$darker-clay: #211e1e;
$dark-clay: #363333;
$light-violet: #9DAEC8;

// Status and Links
$sale: #d72a1f;
$error: #2f4995;
$links: #cc3363;
$disabled: #e0dcd9;
$bloglink-blue: #0000ff;

// switch colors
$light-teal: #9DD4CF;
$dark-teal: #007680;
$inactive: rgba(0, 0, 0, 0.28);

// checkbox colors
$checkbox-border: #D9D9D9;

// wishlist modal background
$wishlist-modal-bg: rgba(255, 255, 255, 0.9);

// Content Page PD component border color
$orange-border: #E1AB7F;

// box-shadow
$box-shadow-color: rgba(0, 0, 0, 0.12);

// general variables
$error-red: $monza;
$grey-text: $primary-black;
$error-border-color: $monza;
$error-text-color: $monza;
$select-icon-border-color: $emperor;
$link-seperator-border-color: $dusty-gray;
$light-border: $mercury;
$grey-border: $mercury;
$silver-border: $silver;
$active-border: $emperor;
$icon-bg-color: $emperor;
$tertiary-link-underline-color: $emperor;
$page-title-background-color: $white;
$page-title-color: $emperor;
$selectbox-border: $silver;
$base-text-color: $primary-black;
$dropdown-background-color: $white;
$dropdown-hover-background-color: $mercury;
$icon-background-color: $emperor;
$base-border-color: $emperor;
$checkbox-border-color: $emperor;
$input-button-border-color: $emperor;
$unselected-input-border-color: $silver;
$down-arrow-border-color: $lilly-pink;
$active-background-color: $wildsand;
$caret-scroll-bg-color: rgba($white, 0.85);
$outline-color: $mulled-wine;
$back-to-top-bg-color: rgba($white, 0.85);
$error-page-background: $selago;
$border-bottom-nav-tab: $lighter-clay;
$border-bottom-selected-nav-tab: $primary-grey;
$selected-nav-tab: $primary-black;
$unselected-nav-tab: $submit-form-disable-grey;
$save-for-later-sale-price: $venetian-red;
$page-navigation-text-link-color: $lilly-pink;

// search
$search-border-color: $silver;
$search-input-text-color: $boulder;
$search-text-color: $emperor;
$no-search-text-color: $emperor;
$search-menu-background-color: $white;
$sort-btn-text-color: $emperor;
$no-result-text-shadow-title-color: rgba(0, 0, 0, 0.25);

// filters
$filters-text-color: $emperor;
$selected-filter-button-background-color: $emperor;
$filter-swatch-border-color: $silver;
$filter-text-input-placeholder-color: $boulder;
$price-filter-button-color: $white;

// monogram
$monogram-selection-blue: $navy-blue;
$monogram-selection-white: $wildsand;
$monogram-selection-pink: $french-rose;
$monogram-selection-gold: $anzac;
$monogram-selection-border-color: $silver;
$monogram-arrow-color: $emperor;

// sizechart colors
$scrollbar-pink: $lilly-pink;
$sizechart-menu-bg: $white;
$table-row-grey: $wildsand;
$table-row-white: $white;
$active-border-bottom-navy: $lilly-navy;
$accordion-border-silver: $silver;
$accordion-header-icon: $charcoal;
$accordion-border-mercury: $mercury;

// custom buttons colors
$primary-bg-color: $lilly-navy;
$primary-color: $white;
$primary-active-bg-color: $mulled-wine;
$primary-disabled-color: $scorpion;
$primary-disabled-bg-color: $alto;

$secondary-bg-color: $white;
$secondary-color: $lilly-navy;
$secondary-active-bg-color: $lilly-navy;
$secondary-disabled-color: $boulder;
$secondary-disabled-bg-color: $white;
$secondary-disabled-border-color: $silver;

$light-modal-background-color: rgba(255, 255, 255, 0.8);

// Primary Button
$btn-primary-bg: $primary-black;
$btn-primary-color: $white;
$btn-primary-border-color: $primary-black;
$btn-primary-hover-bg: $primary-grey;
$btn-primary-hover-color: $white;
$btn-primary-hover-border-color: $primary-grey;
$btn-primary-active-bg: $primary-black;
$btn-primary-active-color: $white;
$btn-primary-active-border-color: $white;

$btn-primary-disabled-bg: $disabled;
$btn-primary-disabled-color: $white;
$btn-primary-disabled-border-color: $disabled;

// Secondary Button
$btn-secondary-bg: $white;
$btn-secondary-color: $primary-black;
$btn-secondary-border-color: $primary-black;
$btn-secondary-hover-bg: $white;
$btn-secondary-hover-color: $primary-grey;
$btn-secondary-hover-border-color: $primary-grey;
$btn-secondary-active-bg: $light-clay;
$btn-secondary-active-color: $dark-clay;
$btn-secondary-active-border-color: $primary-black;

$btn-secondary-disabled-bg: $white;
$btn-secondary-disabled-color: $disabled;
$btn-secondary-disabled-border-color: $disabled;

// Tertiary Link/Button
$tertiary-link-font-color: $primary-black;
$tertiary-link-hover-font-color: $primary-grey;
$tertiary-link-active-font-color: $primary-black;
$tertiary-link-bg: $white;

$tertiary-link-disabled-font-color: $disabled;

// Product colors
$price-color: $emperor;
$sale-price-color: $sale;
$percent-off-color: $emperor;
$selecte-swatch-border-color: $primary-black;
$sale-swatch-indicator-color: $primary-black;
$product-tile-background-color: $white;
$product-unavailable-message-color: $primary-black;
$swatch-selection-border-color: $silver;
$sale-message-color: $monza;
$add-to-cart-message-background: $white;
$add-to-cart-message-color: $emperor;
$swatch-neon-color: $lilly-pink;

// cart & checkout  colors
$divider-color: $mercury;
$seperator-color: $dusty-gray;
$link-color: $emperor;
$card-border-color: $silver;
$card-header-color: $boulder;
$promo-code-border-color: $silver;
$product-total-price-color: $emperor;
$gwp-title-border-color: $silver;
$cart-error: $error-red;
$out-of-stock-color: $error-red;
$coupon-code-color: $lilly-pink;
$product-name-color: $link-color;
$checkout-login-title-border: $lilly-navy;
$size-label-color: $boulder;
$bonus-product-border-color: $silver;
$checkout-button-background-color: $white;
$help-text-color: $emperor;
$order-total-border-color: $silver;
$checkout-total-text-color: $emperor;
$check-mark-color: $lilly-pink;
$receipt-header-border-color: $lilly-pink;
$order-detail-background-color: $white;
$checkout-summary-title-border-color: $silver;
$checkout-confirmation-summary-title-color: $lilly-navy;
$apple-pay-button-color: $white;
$checkout-title-color: $lilly-navy;
$checkout-login-card-heading-border-color: $lilly-pink;
$checkout-line-item-attribute-color: $boulder;
$checkout-line-item-text-color: $emperor;
$cart-gwp-message-color: $sale;
$apply-promo-color: $white;
$billing-address-border-color: $silver;
$availability-error-message-color: $sale;
$checkout-error-border-color: $monza;
$checkout-error-heading-color: $lilly-navy;
$checkout-error-text-color: $emperor;
$checkout-error-background: $lavender-blush;
$gwp-updated-border-color: $monza;
$gwp-updated-background-color: $wildsand;
$checkout-button-border-color: $dark-blue;

//shippment colors
$info-icon-border: $emperor;
$active-info-icon-bg: $boulder;
//heading colors
$heading-pink: $lilly-pink;
// Pick up in store
$store-results-heading: $lilly-pink;
$stock-available: $lilly-pink;
$geolocation-error-color: $monza;

// form elements
$form-control-border-color: $primary-grey;
$form-control-text-color: $emperor;
$form-control-focused-border-color: $primary-grey;
$custom-control-text-color: $primary-black;
$custom-control-border-color: $primary-black;
$custom-control-background-color: $white;
$custom-control-hover-border-color: $primary-grey;
$custom-control-hover-text-color: $primary-grey;
$custom-control-hover-check-color: $primary-grey;
$custom-control-inactive-border-color: $disabled;
$custom-control-inactive-background-color: $disabled;
$custom-control-inactive-text-color: $disabled;
$custom-control-inactive-check-color: $white;
$custom-control-error-check-color: $sale;
$custom-control-error-border-color: $sale;
$form-control-focused-color: $boulder;
$custom-checkbox-background-color: $boulder;
$custom-checkbox-border-color: $white;

$form-group-label-color: $primary-black;
$form-group-label-disabled-color: $disabled;
$form-group-input-label-color: $primary-black;
$form-group-input-label-disabled-color: $clay;
$form-group-error-color: $sale;
$form-group-input-error-color: $sale;
$form-group-helper-color: $primary-black;
$form-group-input-helper-color: $darker-clay;
$form-group-input-text-color: $primary-black;
$form-group-input-text-error-color: $sale;
$form-group-input-text-disabled-color: $clay;

// quick view
$quick-view-button-background-color: $white;
$quick-view-button-border-color: $boulder;
$quick-view-text-color: $lilly-navy;
$quick-view-error-color: $monza;
$quick-view-size-btn-hover-background-color: $wildsand;
$quick-view-size-btn-selected-border-color: $emperor;

//quick buy
$quick-buy-button-background-color: $wishlist-modal-bg;
$quick-buy-button-text-color: $primary-black;

// footer
$footer-backgroud-color: $lighter-clay;
$footer-border-color: $lilly-pink;
$footer-title-color: $emperor;

// Gift card
$gift-card-value-color: $lilly-pink;
$gift-card-error-color: $error-red;
$gift-card-image-background-color: $alabaster;
$gift-card-denomination-hover-background-color: $wildsand;
$gift-card-denomination-selected-border-color: $emperor;
$gift-card-currency-symbol: $emperor;
$gift-card-custom-amount-placeholder-color: $boulder;
$cart-gift-card-value-color: $emperor;
$gift-card-banner-background-color: $gallery;

//homepage
$home-column-heading-color: $hot-pink;
$home-column-btn-border-color: $lilly-navy;
$home-column-btn-link-color: $lilly-navy;
$home-column-content-color: $emperor;
$home-banner-title-color: $emperor;
$home-banner-cta-color: $emperor;
$home-shop-btn-border-color: $white;
$home-shop-text-color: $white;
$home-family-text-color: $lilly-navy;
$home-family-btn-border-color: $lilly-navy;

//account
$account-cards-header-color: $lilly-navy;
$account-card-border-color: $primary-black;
$emptycard-background-color: $lighter-clay;
$need-help-border-color: $silver;
$account-card-hint-text: $boulder;
$account-payment-border-color: $silver;
$account-cards-selector-border-color: $silver;
$account-cards-selector-arrow-color: $silver;
$account-dashboard-text-color: $primary-black;
$account-dashboard-bg-text-color: $primary-grey;

// Header - Mega Menu
$header-text-color: $white;
$header-text-color-alt: $emperor;
$header-border-color: $lilly-pink;
$header-background-color: $white;
$header-background-color-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.56) 29.17%, rgba(0, 0, 0, 0.4) 52.6%, rgba(0, 0, 0, 0.16) 76.56%, rgba(0, 0, 0, 0) 97.92%);
$header-banner-background-color: $lilly-navy;
$header-focus-background-color: $wildsand;
$header-outline-color: $silver;
$header-box-shadow-color: rgba(204, 204, 204, 0.2);

// stripe banner
$stripe-background: $submit-form-disable-grey;
$stripe-background-default: $darker-clay;
$stripe-icon-color: $primary-black;
$stripe-text-color: $white;

//Category Landing Page
$clp-text-color: $emperor;
$clp-tab-border-color: $lilly-pink;

// Store locator
$store-locator-phone: $emperor;
$store-locator-border-color: $silver;
$store-locator-dot-color: $primary-black;
$stores-count-message-color: $sale;

//Wishlist
$wishlist-product-attr-label-color: $boulder;
$wishlist-items-separator-color: $lilly-pink;
$wishlist-item-availability-message-color: $monza;
$wishlist-item-availability-message-bg-color: $wildsand;
$wishlist-search-error-color: $monza;

//My Account - Address Book
$address-action-link-separator: $boulder;
$address-card-border: $silver;
$wishlist-results-table-even-background-color: $wildsand;

//contact us
$contact-us-border-color: $silver;
$customer-service-active-link-arrow-color: $emperor;
$contact-us-success: $links;

// video-component
$video-component-title-color: $emperor;

// Forgot Password
$forgot-password-header-text-color: $emperor;
$forgot-password-text-color: $primary-black;



// spinner
$spinner-background-color: $silver;

// order history
$order-history-dropdown-item-background-color: $wildsand;
$order-history-dropdown-item-color: $grey-text;

//order details
$order-details-adjusted-total-color: $lilly-pink;

// spinner
$spinner-background-color: $silver;

// shop by print
$sbp-quickview-bg-color: $white;
$sbp-drawer-bg-color: $white;
$sbp-quickview-underline: $lilly-pink;
$sbp-drawer-box-shadow-color: rgba(147, 153, 178, 0.54);
$sbp-mini-swatch-text-color: $lilly-navy;
$sbp-header-navy-color: $lilly-navy;
$sbp-header-white-color: $white;
$sbp-header-grey-color: $emperor;

// breadcrumb
$breadcrumb-text-color: $emperor;
$breadcrumb-border-color: $emperor;
$breadcrumb-profile-page-text-color: $primary-black;
$breadcrumb-profile-page-border-color: $primary-black;

//ratings&reviews
$ratings-filter-border-color: $silver;
$ratings-stars-color: $lilly-navy;
$slider-bar-background-color: $silver;

// autocomplete
$suggestion-bg-color: $dropdown-background-color;
$suggestion-selected-bg-color: $active-background-color;

//Pickup Store delivery message PDP
$instore-pickup-message-color: $boulder;

// Switch component
$switch-off-border-color: $clay;
$switch-off-bg-color: $white;
$slider-off-bg-color: $clay;

$swich-off-hover-border-color: $dark-clay;
$slider-off-hover-bg-color: $dark-clay;

$switch-on-bg-color: $light-teal;
$switch-on-hover-bg-color: $dark-teal;
$slider-on-bg-color: $white;

$switch-disabled-bg-color: $light-clay;
$slider-disabled-bg-color: $inactive;

// Alerts
$alert-info-background-color: $light-pink;
$alert-info-border-color: $lavender-blush;
$alert-info-text-color: $emperor;

// Loyalty
$loyalty-background-color: $magenta-pink;
$loyalty-swatch-selected-color: $crimson-red;
$loyalty-swatch-border-color: $gainsboro;
$loyalty-faq-border-color: $lilly-navy;
$loyalty-benefit-title-color: $lilly-navy;
$loyalty-faq-content-color: $emperor;
$loyalty-faq-card-border-color: $silver;
$loyalty-faq-card-bg-color: $white;

//bazaarvoice
$bv-navy-color: $lilly-navy;
$bv-text-color: $emperor;
$bv-border-color: $whiteish-grey;
$bv-page-background-color: $white;
$bv-selected-button-color: $forest-green;
$bv-unselected-button-color: $cadmium-red;
$bv-review-modal-button-text: $white;
$bv-star-color: $lilly-navy;
$bv-button-background-color: $lilly-navy;
$bv-button-border-color: $lilly-navy;

$loyalty-service-error-bg-color: rgba(246, 246, 246, 0.5);
$loyalty-heading-color: $lilly-navy;
$loyalty-referral-background-color: $light-grayish-blue;
$loyalty-referral-color: $emperor;
$loyalty-dashboard-rewards-bg-color: $placebo-purple;
$loyalty-dashboard-rewards-text-color: $emperor;
$loyalty-dashboard-points-bg-color: $magnolia;
$loyalty-dashboard-pointer-empty-color: $pale-lavender;
$loyalty-dashboard-pointer-shadow: $whisper;
$loyalty-dashboard-error-background: $wildsand;
$loyalty-activity-dropdown-arrow-color: $emperor;
$loyalty-activity-text-color: $emperor;
$loyalty-activity-dropdown-selected-bg-color: $wildsand;
$loyalty-activity-divider-line: $emperor;
$loyalty-activity-primary-background: $wildsand;
$loyalty-activity-secondary-background: $titan-white;
$loyalty-activity-tertiary-background: $snow;
$loyalty-order-details-error: $cadmium-red;
$loyalty-order-details-points-text: $crimson-red;
$loyalty-heading-color: $lilly-navy;
$loyalty-points-earned-this-year-color: $lilly-navy;
$loyalty-points-earned-this-year-text-color: $emperor;
$loyalty-account-view-dashboard-color: $emperor;
$loyalty-benefit-lock-color: rgba(25, 25, 61, 0.5);
$loyalty-dashboard-header-border-color: $lilly-navy;
$loyalty-border-color: $lilly-navy;
$loyalty-voucher-card-background-color: $alabaster;
$loyalty-benefits-scrollbar-color: $lilly-navy;
$loyalty-birthdaybanner-bg-color: $lilly-navy;
$loyalty-badge-background-color: $lilly-navy;
$loyalty-badge-text-color: $white;
$loyalty-new-tag-color: $lilly-navy;
$loyalty-guest-new-tag-color: $lilly-navy;
$loyalty-vouchers-scrollbar-color: $dusty-gray;
$loyalty-vertical-scrollbar-color: $lilly-navy;
$loyalty-enroll-background-color: $white;

//Stylitics
$stylitics-jumplink-bg-color: $white;
$stylitics-jumplink-border-color: $boulder;
$stylitics-jumplink-text-color: $emperor;
$stylitics-text-hover-color: $boulder;
$stylitics-badge-border-color: $boulder;
$stylitics-badge-background-color: $wildsand;
$stylitics-default-bundle-border-color: $grey3;
$stylitics-active-bundle-border-color: $boulder;

// Radio Components
$radio-color: $primary-black;
$radio-hover-color: $primary-grey;
$radio-disabled-color: $disabled;
$radio-error-color: $sale;

// Content pages background

$content-background-color: #9DAEC8;